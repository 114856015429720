#scrollTop {
  position: fixed;
  width: 1px;
  left: 85%;
  bottom: 20%;
  height: 0px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  color: #a2b7b3;
  background-color: transparent;
  box-shadow: none;
}
